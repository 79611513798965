<template>
  <div class="container">
    <img
      class="top"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/carOwner_hailuo/top_01.png"
    />
    <div class="box">
      <img
        class="card"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/carOwner_hailuo/card1.png"
      />
      <img
        class="card"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/carOwner_hailuo/card2_01.png"
      />
      <img
        class="card"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/carOwner_hailuo/card3.png"
      />
    </div>
    <div class="form">
      <van-field
        v-model="form.phone_number"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
      />

      <van-field
        v-model="form.phone_code"
        type="number"
        center
        maxlength="6"
        autocomplete="one-time-code"
        placeholder="请输入验证码"
        class="input-code"
      >
        <template slot="right-icon">
          <div class="code" v-show="sendAuthCode" @click="getAuthCode">
            获取验证码
          </div>
          <div class="code" v-show="!sendAuthCode">
            <span>{{ auth_time }}秒</span>重新获取
          </div>
        </template>
      </van-field>
      <img
        class="submit"
        @click="onSubmit"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/carOwner_hailuo/submit.png"
      />

      <div class="checked">
        <van-checkbox
          checked-color="#ffd614"
          v-model="checked"
          icon-size="14px"
        >
          &nbsp;我已阅读并同意<span class="book" @click="dialogInfo"
            >《车主出行惠服务协议》</span
          >
        </van-checkbox>
      </div>
    </div>
    <div class="info">
      <img
        class="title"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/carOwner_hailuo/title.png"
      />
      <div class="text">1.活动对象:<br>2G/3G/4G/5G手机用户。</div>
      <div class="text">
        本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉!为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗。
      </div>
      <div class="text">2.活动内容:</div>
      <div class="text">
        订购车主畅享会员，29.9元/月，每30天可领取车主通用券10元*2张+视听会员权益*1张+打车券10元*1张，到期前24小时内将自动扣费续订。
      </div>
      <div class="text example">
        例如张三在2024年4月1日10:56分订购了车主畅享会员，那么在2024年4月1日10:56分~2024年4月30日10:55期间，张三可领取10元车主通用券2张+视听会员月卡1张+10元打车券1张。如张一在2024年4月30日10:55前未退订服务，那么在2023年4月30日10:55~2023年4月31日10:55期间将自动扣费下一个30天计费周期的费用。
      </div>
      <div class="text">
        3.生失效方式:
        订购立即生效，当前计费周期费用(30天)一次性扣除下一个计费周期自动续订，退订需在到期前24小时。
      </div>
      <div class="text">4.权益领取使用及有效期:</div>
      <div class="text">
        会员权益每个计费周期(30天)限领1次，逾期作废。
      </div>
      <div class="text">(1) 车主通用券使用说明：</div>

      <div class="text">
        ①本券在加油、充电、高速、停车、代驾、违章查缴、年检、洗车、租车、道路救援、驾照翻译可用，仅支持在有效期内使用。
      </div>

      <div class="text">②本券不可兑换现金，不可找零。</div>
      <div class="text">
        ③单笔支付只可核销1张优惠，不得转赠、共享，支付时不可以与其他优惠叠加使用。
      </div>
      <div class="text">④使用时，优惠金额-10元。</div>
      <div class="text">
        ⑤有效期自红包生效时间起7日内有效(请在优惠红包详情页面关注具体有效期信息)，建议领取后尽快使用，过期后无法使用。
      </div>

      <div class="text">⑥本券使用门槛以支付宝订单金额为准，不能拆分使用。</div>
      <div class="text">
        ⑦本券限实名认证用户才可使用，未实名认证用户需完成支付宝实名认证后可使用该优惠。
      </div>
      <div class="text">⑧本券仅限在中国大陆地区使用。</div>
      <div class="text">
        ⑨订单发生全额退款时，尚在有效期内的优惠可再次使用，退款后的红包有效期不变。如退款时红包超出有效期或有效期内订单部分退款，红包无法再次使用。
      </div>
      <div class="text">
        ⑩仅限中国大陆地区的支付宝实名用户参与，同一支付宝账号、手机号、身份证、手机终端，符合以上任一条件的，均视为同一用户。
      </div>
      <div class="text">
        ⑪本券仅支持支付宝可用的加油、充电、高速、停车场景，以及支付宝车生活小程序的代驾、违章查缴、年检、洗车、租车、道路救援、驾照翻译使用，商户自身/三方小程序不支持使用。
      </div>
      <div class="text">
        ⑫如果用户存在或涉嫌违法违规行为情况(包括不限于洗钱、虚假交易、作弊、套现、赌博、刷信用、支付宝基于自身风险政策或策略认定的风险交易等)的，支付宝将撤销用户活动参与资格和奖品使用资格，并有权回收用户已经获得的奖励权益(包括已消费金额)。
      </div>
      <div class="text">(2)视听会员权益使用说明：</div>

      <div class="text">
        视听会员权益如腾讯爱奇艺登录对应平台即可使用；
      </div>

      <div class="text">（3）打车券使用说明：</div>

      <div class="text">—滴滴快车/专车</div>
      <div class="text">
        ①本品为代金券直充，仅支持账号绑定手机号码充值。请用户在充值前确保号码已开通滴滴账号。
      </div>
      <div class="text">②虚拟商品，一经提交充值不支持退换。</div>
      <div class="text">
        ③一笔订单只能使用一张优惠券，并且只能抵扣充值到账户之后的生成的订单，优惠券不拆分、不找零，请合理选择是否使用优惠券，优惠不足部分需用电子支付方式补足。拼车、特惠快车无法使用。
      </div>

      <div class="text">—高德打车：</div>

      <div class="text">
        ①本品为代金券直充，仅支持账号绑定手机号码充值。请用户在充值前确保号码已开通滴滴账号。
      </div>
      <div class="text">
        ②仅限在高德地图端内打车时使用，出租车、一口价订单不能使用。
      </div>
      <div class="text">
        ③打车券有效期为充值成功之日起7天内有效，请在券有效期内使用，过期失效作废。同一订单优惠券最多可使用一张，不可叠加使用;券仅限一次性使用，不可拆分，不可转让，不可提现。
      </div>
      <div class="text">
        ④充值成功后，登录[高德地图]
        app，点击[我的]-[钱包卡券]-[券]处查看使用规则及有效期情况。
      </div>
      <div class="text">
        对券的使用等有任何疑问可以拨打高德地图客服电话: 400-880-2252。
      </div>

      <div class="text">5.退订方式</div>
      <div class="text">
        退订用户需在会员权益到期前24小时联系取消，在下一个计费周期生效，且取消后6个月内不能再次订购;
      </div>

      <div class="text">
        如需退订本产品，请登录【支付宝],点击右下角【我的]-【设置]-【支付设置]-【免密支付/自动扣款]在自动扣款界面，找到要取消自动续费的服务，点击【服务]。在服务详情界面，点击最下方【关闭服务]。出现选项，点击【关闭服务],即可成功退订本产品，退订后所有权益如在权益规定有效期内均可使用，逾期作废;
      </div>

      <div class="text">
        6.如对活动有疑问或更多需求，请咨询在线客服或拨打4001002501。
      </div>

      <div class="text">[订购须知]</div>
      <div class="text">
        1、本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉!
      </div>
      <div class="text">
        2、为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，道防网络电信诈骗。
      </div>
      <div class="text">
        3、虚拟权益一旦领取不支持退款，请谨慎选择。
      </div>
    </div>
    <v-dialog :visibility.sync="visibility" />
  </div>
</template>

<script>
import { alipayCallbackPay } from "@/api/carOwner.js";
import { code } from "@/api/carOwner_zhengxing/receive.js";
import { generateSign } from "@/utils/common";
import VDialog from "./dialog/index.vue";
export default {
  data() {
    return {
      form: {
        phone_number: "",
        phone_code: "",
      },
      sendAuthCode: true,
      checked: false,
      auth_time: 0,
      state: false,
      visibility: false,
    };
  },
  components: {
    VDialog,
  },
  methods: {
    // 验证码
    async getAuthCode() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone_number } = this.form;
      if (!filterPhone.test(phone_number)) {
        this.$dialog.alert({ message: "手机号错误" });
        return;
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const res = await code({ phone_number });
      this.$toast.clear();
      if (res.code != 0) {
        this.$dialog.alert({ message: res.message });
      } else {
        // 设置倒计时秒
        this.sendAuthCode = false;
        this.auth_time = 60;
        let auth_timetimer = setInterval(() => {
          this.auth_time--;
          if (this.auth_time <= 0) {
            this.sendAuthCode = true;
            clearInterval(auth_timetimer);
          }
        }, 1000);
      }
    },
    async onSubmit() {
      let filtercode = /^\d{6}$/;

      const { phone_number, phone_code } = this.form;
      if (!filtercode.test(phone_code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else if (!this.checked) {
        this.$toast({
          duration: 8000,
          message: "请阅读并同意车主出行惠服务协议",
        });
      } else {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          mask: true,
          message: "加载中...",
        });
        const { enity = "" } = this.$route.query;
        const res = await alipayCallbackPay({
          phone_number,
          phone_code,
          item_name: "车主畅享会员",
          callback_name: "巨量",
          callback: location.search,
          auth: generateSign("HLXIAOYANG", "OGH*O&GHO19"),
        },
        enity);
        this.$toast.clear();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message,
          });
        } else {
          location.href = `alipays://platformapi/startapp?appId=20000067&url=${encodeURIComponent(
            res.sign_str
          )}`;
        }
      }
    },
    dialogInfo() {
      this.visibility = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #22a0fe;
  .top,
  .card {
    width: 100%;
  }

  .box {
    padding: 0 25px;
    margin-top: -590px;
    // margin-bottom: 60px;

    .card {
      padding-bottom: 33px;
    }
  }

  .form {
    background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/carOwner_hailuo/input.png")
      center center/contain no-repeat;
    height: 604px;
    width: 750px;
    padding-top: 33px;
    box-sizing: border-box;

    ::v-deep .van-cell {
      width: 560px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 20px;
      border: solid 2px #006dbf;
      margin: 0px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 23px;

      &.input-code {
        margin-top: 20px;

        .code {
          width: 190px;
          height: 100px;
          text-align: center;
          line-height: 100px;
          color: #006dbf;
          font-size: 28px;
          font-weight: normal;
          background-color: #cbf0ff;
          border-left: solid 2px #006dbf;
        }
      }

      .van-field__body {
        height: 100%;

        input {
          height: 100%;
        }
      }

      &::after {
        border: none;
      }

      .van-field__control {
        color: #006dbf;
        font-weight: normal;
        &::-webkit-input-placeholder {
          font-size: 34px;
          font-weight: 40;
          color: #7fb6df;
        }
      }
    }

    .submit {
      width: 572px;
      display: block;
      margin: 112px auto 0;
    }

    .checked {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: 400;
      padding: 10px 0 300px;

      ::v-deep .van-checkbox {
        width: auto;
        .van-checkbox__icon .van-icon{
          border: 1px solid #006dbf;
        }

        &__label {
          margin-left: 4px;
          color: #006dbf !important;

          .book {
            color: #fdff5d;
          }
        }
      }
    }
  }

  .info {
    padding: 66px 26px 160px;

    .title {
      width: 224px;
      display: block;
      margin: 0 auto;
      padding-bottom: 32px;
    }
    .text {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #fff;
    }
    .example {
      font-size: 22px;
      letter-spacing: 0px;
      color: #fff;
    }
  }
}
</style>
